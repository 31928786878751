<template>
  <Page>
    <div class="forgot-password-confirmation">
      <div
        v-if="loading.component"
        class="forgot-password-confirmation__preloader-container"
      >
        <Preloader />
      </div>

      <template v-else>
        <div class="forgot-password-confirmation__title">Password recovery</div>

        <div class="forgot-password-confirmation__text">
          Please enter the code we have sent to the email account
          <div class="forgot-password-confirmation__highlighted-text">
            {{ email }}
          </div>
          , or click the link in the email.
        </div>

        <div class="forgot-password-confirmation__code-verification-container">
          <code-verification
            :loading="loading"
            :error="errors.code"
            :on-change="clearCodeVerificationError"
            :on-focus="clearCodeVerificationError"
            :on-verification="onVerifyConfirm"
            :disabled="lockout"
          ></code-verification>
        </div>

        <div class="forgot-password-confirmation__counter-container">
          <div class="forgot-password-confirmation__button-container">
            <form>
              <Button
                v-if="lockout"
                :disabled="timeLeft > 0"
                auto-qa-id="forgot-password-confirmation-send-new-code-button"
                :onClick="onSendNewCode"
                variant="outlined"
              >
                Send New Code
              </Button>
              <input
                type="hidden"
                name="email"
                :value="email"
              />
              <input
                type="hidden"
                name="__RequestVerificationToken"
                :value="antiForgeryToken"
              />
            </form>
          </div>
          <div
            v-if="timeLeft > 0"
            class="forgot-password-confirmation__counter"
          >
            {{ timeLeft }}s
          </div>
        </div>
        <div class="forgot-password-confirmation__no-access-holder">
          No access to email
          <div
            class="forgot-password-confirmation__question-icon"
            v-tooltip="{
              content: `
                  If you cannot access this code, please
                  contact 3Shape Support for assistance.
              `,
              offset: [8, 8]
            }"
          >
            <img :src="images.questionMark" />
          </div>
        </div>
      </template>
    </div>
  </Page>
</template>

<script src="./ForgotPasswordConfirmation.js"></script>

<style src="./ForgotPasswordConfirmation.scss" lang="scss" scoped></style>
