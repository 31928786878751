<template>
  <Page>
    <div class="login-mfa">
      <verify-mfa-code
        :loading="loading"
        :errors="errors"
        :clear-verification-error="clearVerificationError"
        :on-verify-confirm="onVerifyConfirm"
      ></verify-mfa-code>
    </div>
  </Page>
</template>

<script src="./LoginMfa.js"></script>

<style src="./LoginMfa.scss" lang="scss" scoped></style>
