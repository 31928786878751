import { CodeVerification } from 'account-ui-components'
import { reactive } from 'vue'
import Api from '@/api'
import config from '@/api/config'
import { getEnv } from '@/helpers'
import supermanIcon from '@/assets/img/superman.svg'

const RegistrationVerifyWithShortCode = {
  name: 'RegistrationVerifyWithShortCode',

  props: {
    userId: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  },

  components: {
    CodeVerification
  },

  setup(props) {
    const env = getEnv()
    const loading = reactive({
      component: false,
      resend: false,
      verification: false
    })
    const errors = reactive({
      code: ''
    })
    const images = {
      superman: supermanIcon
    }

    const onVerifyConfirm = async (code) => {
      loading.verification = true

      try {
        await Api.verifyAccountWithShortCode(props.userId, code)

        const { profile } = config.hosts[env]
        window.location.href = `${profile}/#/account-activation`
      } catch (err) {
        errors.code = 'Incorrect code. Enter the code again.'
        loading.verification = false
      }
    }

    const clearCodeVerificationError = () => {
      if (errors.code) {
        errors.code = ''
      }
    }

    return {
      images,
      loading,
      errors,
      onVerifyConfirm,
      clearCodeVerificationError
    }
  }
}

export default RegistrationVerifyWithShortCode
