import { ref, reactive } from 'vue'
import { CodeVerification, Button } from 'account-ui-components'
import Api from '@/api'
import config from '@/api/config'
import { getEnv } from '@/helpers'
import Page from '@/components/Page/Page.vue'
import DownloadAuthenticatorApp from '@/components/DownloadAuthenticatorApp/DownloadAuthenticatorApp.vue'
import LinkAccountToAuthenticatorApp from '@/components/LinkAccountToAuthenticatorApp/LinkAccountToAuthenticatorApp.vue'
import VerifyMfaCode from '@/components/VerifyMfaCode/VerifyMfaCode.vue'
import mfaIcon from '@/assets/img/mfa.svg'
import shieldIcon from '@/assets/img/shield.svg'

const MultiFactorAuthentication = {
  name: 'MultiFactorAuthentication',

  props: {
    qrCodeDataUri: {
      type: String,
      default: null
    },
    multiFactorKey: {
      type: String,
      default: null
    }
  },

  components: {
    CodeVerification,
    Button,
    Page,
    DownloadAuthenticatorApp,
    LinkAccountToAuthenticatorApp,
    VerifyMfaCode
  },

  setup() {
    const env = getEnv()
    const loading = reactive({
      verification: false
    })
    const errors = reactive({
      verification: ''
    })
    const step = ref('getStarted')
    const items = [
      {
        icon: mfaIcon,
        title: 'Add an extra layer of security',
        text: 'Enter your password and a unique verification code from an authenticator app or email.'
      },
      {
        icon: shieldIcon,
        title: 'Keep your personal and patient data safe',
        text: 'Even if someone gets your password, they can’t access your account without this second step.'
      }
    ]

    const onBackClick = () => {
      window.history.back()
    }

    const changeStep = (newStep) => {
      step.value = newStep
    }

    const clearVerificationError = () => {
      if (errors.verification) {
        errors.verification = ''
      }
    }

    const onVerifyConfirm = async (totpCode) => {
      loading.verification = true

      try {
        await Api.activateMfa(totpCode)

        const { profile } = config.hosts[env]

        window.location.href = `${profile}/user?section=settings`
      } catch (error) {
        errors.verification = 'Incorrect code. Enter the code again.'
        loading.verification = false
      }
    }

    return {
      loading,
      errors,
      step,
      items,
      onBackClick,
      changeStep,
      clearVerificationError,
      onVerifyConfirm
    }
  }
}

export default MultiFactorAuthentication
