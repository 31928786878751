import { reactive } from 'vue'
import Api from '@/api'
import Page from '@/components/Page/Page.vue'
import VerifyMfaCode from '@/components/VerifyMfaCode/VerifyMfaCode.vue'

export default {
  name: 'LoginMfa',

  components: {
    Page,
    VerifyMfaCode
  },

  setup() {
    const loading = reactive({
      verification: false
    })
    const errors = reactive({
      verification: ''
    })

    const clearVerificationError = () => {
      if (errors.verification) {
        errors.verification = ''
      }
    }

    const onVerifyConfirm = async (totpCode) => {
      loading.verification = true

      try {
        await Api.processTotpCode(totpCode)

        const url = new URL(window.location.href)
        const params = new URLSearchParams(url.search)
        const returnUrl = params.get('returnUrl')

        window.location.href = returnUrl
      } catch (error) {
        errors.verification = 'Incorrect code. Enter the code again.'
        loading.verification = false
      }
    }

    return {
      loading,
      errors,
      clearVerificationError,
      onVerifyConfirm
    }
  }
}
